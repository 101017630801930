import React from 'react';
import './AppInfo.css';
import appIcon from './appicon.png';

const AppInfo: React.FC = () => {
  return (
    <section className="app-info">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4 text-center">
            <img src={appIcon} alt="App Icon" className="rounded img-fluid app-icon" />
          </div>
          <div className="col-md-8 my-3">
            <h2>About Baby E.A.S.Y.</h2>
            <p>The app features tools designed to help you care for and monitor your newborn's activities. Based on Tracy Hogg's E.A.S.Y. method, it provides a schedule to make implementing this approach easier and more effective.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppInfo;
