// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBTDznuHz5E9Ywcqeu4G4mymyDPoZD1K-U",
  authDomain: "baby-care-2892b.firebaseapp.com",
  databaseURL: "https://baby-care-2892b-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "baby-care-2892b",
  storageBucket: "baby-care-2892b.appspot.com",
  messagingSenderId: "1046542671949",
  appId: "1:1046542671949:web:d61cdc4c3191d997d83c04",
  measurementId: "G-GSG9B6P0SZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

export {
  app,
  analytics,
  database
};
